/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import SignatureProgramPage from './SignatureProgramPage';
import TransformationTribeProgramPage from './TransformationTribeProgramPage';
import BuildYourOwnBoxPage from './BuildYourOwnBoxPage';
import HauteMomProgramPageV2 from './HauteMomProgramPageV2';

import { getQueryParams } from 'core/api/utils';
import cacheUpdater, { updateUserQuery } from 'core/api/cache/updaters';

import { SIGN_UP } from 'core/api/apollo/mutations';

import { PROGRAM_TYPE_VALUES, PROGRAM_TYPE_OPTIONS } from 'onramp/constants';

import { FormGroup, Grid, H2, Text, Button, Flex, Input } from 'core/ui/atoms';
import { Radio, InputGroupLabel } from 'core/ui/atoms/V2';
import { Page, PageInfo, PageContent, HeroImage } from 'onramp/ui/components/V2';
import SplitPanelModal from 'core/ui/components/V2/SplitPanelModal';
import NotDeliverable from 'core/ui/components/V2/NotDeliverable';
import BodyScrollManager from 'core/ui/components/BodyScrollManager';

import { BG_SPLIT_1, BG_SPLIT_3, BG_SPLIT_4, BG_SPLIT_5, BG_KASIA } from 'core/assets/images/V2';
import { isViewportAbove } from 'core/ui/breakpoints';

import { onrampNext } from 'onramp/api/navigation';
import { userHasCourierAddress } from 'core/api/Account';

import { getCityFromZipcode } from 'onramp/helpers';

const DisplayProgramType = (props) => {
  const ProgramComponents = {
    [PROGRAM_TYPE_VALUES.COURIER]: BuildYourOwnBoxPage,
    [PROGRAM_TYPE_VALUES.SHIPPING]: SignatureProgramPage,
    [PROGRAM_TYPE_VALUES.TRANSFORMATION_TRIBE]: TransformationTribeProgramPage,
    [PROGRAM_TYPE_VALUES.POST_PARTUM_HOTMOM]: HauteMomProgramPageV2,
    [PROGRAM_TYPE_VALUES.PRE_SUBSCRIBED]: SignatureProgramPage,
  };

  const { selectedProgramType } = props;
  const SelectedProgramComponent = ProgramComponents[selectedProgramType];
  return <SelectedProgramComponent {...props} />;
};

const IMAGE_MAPPER = {
  [PROGRAM_TYPE_VALUES.COURIER]: BG_SPLIT_4,
  [PROGRAM_TYPE_VALUES.SHIPPING]: BG_KASIA,
  [PROGRAM_TYPE_VALUES.TRANSFORMATION_TRIBE]: BG_SPLIT_3,
  [PROGRAM_TYPE_VALUES.PRE_SUBSCRIBED]: BG_SPLIT_4,
  [PROGRAM_TYPE_VALUES.POST_PARTUM_HOTMOM]: BG_SPLIT_5,
};
//--------------

function StepForm({ currentUser, setSelectedImage, ...props }) {
  const defaultProgramType = PROGRAM_TYPE_VALUES.SHIPPING;
  const [selectedProgramType, setSelectedProgramType] = useState(defaultProgramType);
  const [selectedProgramSchedule, setSelectedProgramSchedule] = useState(null);

  const programSchedule = PROGRAM_TYPE_OPTIONS[selectedProgramType].schedule;

  useEffect(() => {
    const initialSchedule = programSchedule.find((v) => !v.disable && !v.hidden);
    setSelectedProgramSchedule(initialSchedule.value);
    setSelectedImage(IMAGE_MAPPER[selectedProgramType]);
  }, [selectedProgramType, programSchedule, setSelectedImage]);

  return (
    <Fragment>
      <CustomHeader>Hooray, we deliver to you!</CustomHeader>
      <CityNameText text-center>
        {getCityFromZipcode(currentUser.primaryAddress.zipCode)}
      </CityNameText>

      <CustomFormGroup role="radiogroup" aria-labelledby="programType">
        <InputGroupLabel id="programType">Program Type</InputGroupLabel>
        <ProgramTypeDesc text-size="small">
          For all curated Programs, you can swap up to two items per week.
        </ProgramTypeDesc>
        <Grid gap="1.2em">
          {Object.values(PROGRAM_TYPE_OPTIONS).map((option) => {
            if (option.hidden) return null;

            const isCourierAddress = userHasCourierAddress(currentUser);
            const isCourierOption = option.value === PROGRAM_TYPE_VALUES.COURIER;

            if ((isCourierAddress && isCourierOption) || !isCourierOption) {
              return (
                <section key={`program-type-${option.value}`}>
                  <CustomRadio
                    key={`program-type-input-${option.value}`}
                    name="accountType"
                    label={option.label}
                    value={option.value}
                    checked={selectedProgramType === option.value}
                    disable={option.disable}
                    hidden={option.hidden}
                    onChange={(e) => setSelectedProgramType(e.target.value)}
                    subLabel={
                      option.description && (
                        <InputGroupSublabel key={`program-type-description-${option.value}`}>
                          {option.description}
                        </InputGroupSublabel>
                      )
                    }
                  />
                </section>
              );
            }

            return null;
          })}
        </Grid>
      </CustomFormGroup>

      <CustomFormGroup role="radiogroup" aria-labelledby="programSchedule">
        <InputGroupLabel id="programSchedule">Program Schedule</InputGroupLabel>
        <Grid gap="1.2em">
          {programSchedule.map(
            (option) =>
              !option.hidden && (
                <section key={`program-schedule-${option.value}`}>
                  <CustomRadio
                    name="accountSchedule"
                    label={option.label}
                    value={option.value}
                    checked={selectedProgramSchedule === option.value}
                    onChange={() => setSelectedProgramSchedule(option.value)}
                    disable={option.disable}
                    hidden={option.hidden}
                    subLabel={
                      option.description && (
                        <InputGroupSublabel key={`program-schedule-description-${option.value}`}>
                          {option.description}
                        </InputGroupSublabel>
                      )
                    }
                  />
                </section>
              ),
          )}
        </Grid>
      </CustomFormGroup>

      <DisplayProgramType
        {...props}
        currentUser={currentUser}
        selectedProgramType={selectedProgramType}
        selectedProgramSchedule={selectedProgramSchedule}
      />
    </Fragment>
  );
}

StepForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  cache: PropTypes.object,
  setSelectedImage: PropTypes.func.isRequired,
};

function InitOrderPage({ currentUser, cache }) {
  const [user, setUser] = useState(currentUser);
  const [modal, setModal] = useState(true);
  const [selectedImage, setSelectedImage] = useState(BG_SPLIT_1);
  const initialZipCode = getQueryParams().zipcode;
  const [enteredZipCode, setEnteredZipCode] = useState(initialZipCode);
  const [zipCodeErrors, setZipCodeErrors] = useState(false);

  const onSignUp = ({ signUp }) => {
    if (signUp.user && signUp.user.primaryAddress && signUp.user.primaryAddress.supported) {
      setUser(signUp.user);
      setModal(false);
    } else {
      setZipCodeErrors(true);
    }
  };

  const [signUpWithZipCode] = useMutation(SIGN_UP, {
    variables: { input: { zipCode: enteredZipCode, firstName: '', lastName: '', email: '' } },
    skip: !enteredZipCode,
    update: cacheUpdater('signUp', updateUserQuery),
    onCompleted: onSignUp,
  });

  const onSuccess = () => onrampNext(user, cache);

  useEffect(() => {
    if (modal) {
      if (!user || !user.primaryAddress || !user.primaryAddress.zipCode) {
        const zipCode = getQueryParams().zipcode;
        if (zipCode) {
          signUpWithZipCode({
            input: { zipCode: enteredZipCode, firstName: '', lastName: '', email: '' },
          });
        }
      } else {
        setModal(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, user, signUpWithZipCode]);

  return (
    <CustomPage backgroundColor="#E7EBE9">
      <BodyScrollManager disabled={isViewportAbove('sm') && true} />
      {modal && (
        <Fragment>
          {zipCodeErrors ? (
            <NotDeliverable onClick={() => setZipCodeErrors(false)} />
          ) : (
            <SplitPanelModal image={BG_SPLIT_1} imageTitle="Start Your Transformation">
              <CustomContent>
                <ModalH2>Start Your Transformation</ModalH2>
                <ModalDesc>Enter ZIP Code to check availability</ModalDesc>
                <CustomInput
                  type="text"
                  placeholder="Zip Code*"
                  value={enteredZipCode}
                  onChange={(e) => setEnteredZipCode(e.target.value)}
                  validators={['required']}
                  errorMessages={[]}
                />
                <CustomButton onClick={signUpWithZipCode} btn-primary btn-lg fullWidth>
                  CHECK AVAILABILITY
                </CustomButton>
              </CustomContent>
            </SplitPanelModal>
          )}
        </Fragment>
      )}
      <CustomPageInfo hideBackButton={true}>
        <CustomInfoImage
          src={selectedImage}
          title="Start Your Transformation"
          alt="A couple eating donuts"
        />
      </CustomPageInfo>
      <CustomPageContent>
        {!modal && (
          <StepForm
            onSuccess={onSuccess}
            currentUser={user}
            cache={cache}
            setSelectedImage={setSelectedImage}
          />
        )}
      </CustomPageContent>
    </CustomPage>
  );
}

InitOrderPage.propTypes = {
  currentUser: PropTypes.object,
  cache: PropTypes.object.isRequired,
};

const CustomInfoImage = styled(HeroImage)`
  ${({ theme }) => theme.max('md')`
    transform: none;
    img {
      width: 100%;
      min-height: 505px;
      object-fit: cover;
    }
  `}

  ${({ theme }) => theme.max('sm')`
    position: relative;
    top: 0;
    transform: none;
  `}
`;

const InputGroupSublabel = styled.span`
  font-size: 0.8em;
  font-weight: 300;
  font-style: italic;
  padding-bottom: 1em;
  padding-left: 0.5em;
`;

const CustomPage = styled(Page)`
  background-color: ${({ theme }) => theme.colors.creamWhite};
  height: 100vh;
  ${({ theme }) => theme.max('md')`
    grid-template-columns: 50% 50%;
  `}
  ${({ theme }) => theme.max('sm')`
    grid-template-columns: 1fr;
    height: 100%;
  `}
`;

const CustomPageInfo = styled(PageInfo)`
  height: 100vh;
  ${({ theme }) => theme.max('sm')`
    height: 100%;
    padding: 0 0 2.5rem;
  `}
`;


const CityNameText = styled(Text)`
  font-weight: 500;
  font-size: 17px;
  text-transform: capitalize;
`;
const CustomFormGroup = styled(FormGroup)`
  margin-bottom: 1.5em;
  ${({ theme }) => theme.max('sm')`
    margin-top: 2rem;
  `}
`;

const CustomRadio = styled(Radio)`
  margin-bottom: 1em;
`;

const CustomPageContent = styled(PageContent)`
  overflow-y: auto;
  padding-bottom: 4vh;
  margin-bottom: 2vh;
  overflow-x: hidden;
`;

const Content = styled(Flex)`
  padding: 3vh 5vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #36443a;
`;

const CustomInput = styled(Input)`
  border: 1px solid #36443a;
  border-radius: 0;
  background: #fff;

  ${({ theme }) => theme.max('sm')`
    border-radius: 0.5rem;
  `}
`;

const CustomButton = styled(Button)`
  border-radius: 5px;
  border: 1px solid #36443a;
  background: #c1d3c1;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  color: #36443a;
  font-family: ${({ theme }) => theme.typography.familyPoppins};
`;

const CustomHeader = styled(H2)`
  text-align: center;
  margin: 0 auto;
`;

const CustomContent = styled(Content)`
  color: #fff !important;
  text-align: center;
  height: 100%;
  ${({ theme }) => theme.max('sm')`
    margin-top: 0;
  `}
`;

const ModalH2 = styled(H2)`
  color: #fff !important;
  text-align: center;
  font-family: ${({ theme }) => theme.typography.familyLarken};

  ${({ theme }) => theme.max('sm')`
    display: none;
  `}
`;

const ModalDesc = styled(Text)`
  color: #fff !important;
  font-family: ${({ theme }) => theme.typography.familyPoppins};
  ${({ theme }) => theme.max('sm')`
    margin-top: 0;
  `}
`;

const ProgramTypeDesc = styled(Text)`
  margin-top: 0;
`;

export default InitOrderPage;
