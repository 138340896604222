import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useReferralCode from 'core/hooks/useReferralCode';

import { Flex, Text } from 'core/ui/atoms';

import { DEFAULT_PROMO_CODE } from 'onramp/constants';

function PromoBanner({ disabled = false }) {

  let { code, unit, amount } = DEFAULT_PROMO_CODE;

  const { referralCode, partnerCode } = useReferralCode();

  const isReferraCodePresent = referralCode && Object.entries(referralCode).length !== 0;

  if ((disabled && isNotReorderFromLulu()) || !isReferraCodePresent) {
    return null;
  }

  function isNotReorderFromLulu() {
    return partnerCode !== 'lululemon';
  }

  if (isReferraCodePresent) {
    code = referralCode.code;
    unit = referralCode.discountType === 'PERCENTAGE' ? '%' : '$';
    amount = referralCode.discountType === 'PERCENTAGE' ? referralCode.discountPercentage : (referralCode.discountAmount.amount / 100);
  }

  return (
    <BannerContent>
      {
        isNotReorderFromLulu() ?
        <Text no-margin text-center>
          Apply code {code} to get {amount}{unit} off on your first order
        </Text> :
        <Text no-margin text-center>
          Lululemon partner discount: 25% off first orders and 15% off subsequent orders applied automatically at checkout
        </Text>
      }
    </BannerContent>
  );
}

PromoBanner.propTypes = {
  disabled: PropTypes.bool,
};

const BannerContent = styled(Flex)`
  align-items: center;
  justify-content: center;
  background: ${(p) => p.theme.colors.secondary };
  padding: 0.5em;
`;

export default PromoBanner;
