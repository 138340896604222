import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex, Text, Link } from 'core/ui/atoms';

function TopBanner({ disabled = false }) {

  const MATCHA_MENU_DROP = 'https://gomethodology.com/2025/04/01/the-matcha-drop-ships-nationwide-april-21st';

  if (disabled) {
    return null;
  }

  return (
    <Fragment>
      <TopBannerContent>
        <TopBannerText no-margin text-center>
          To get {' '}
          <Link href={MATCHA_MENU_DROP} target='_blank'>The Matcha Drop</Link>,
          choose any "Meat + Seafood" program for the delivery week of April 21st.
        </TopBannerText>
      </TopBannerContent>
    </Fragment>
  );
}

TopBanner.propTypes = {
  disabled: PropTypes.bool,
};

const TopBannerContent = styled(Flex)`
  align-items: center;
  justify-content: center;
  background: #C5D1C0;
  padding: 0.5em;
  margin-top: 60px;
`;

const TopBannerText = styled(Text)`
  color: #36443A
`;

export default TopBanner;
