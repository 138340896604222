import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// import { eventBannerEnable } from 'reactAppConstants'; // TODO: setup env variable in AWS
import useUser from 'core/hooks/useUser';

import {  PromoBanner, TopBanner } from 'onramp/ui/components';
import Header from 'onramp/ui/components/V2/OnrampHeader';
// import EventBanner from 'onramp/ui/components/Banner';
import CutoffModal from 'onramp/ui/components/Cutoff/Modal';

function OnrampLayout(props) {
  const { promoBannerEnabled, inverse, disableProgress, children } = props;
  const { currentUser } = useUser();

  const isReorder = currentUser && currentUser.reorder;

  return useMemo(() => {
    return (
      <Main>
        <MainContainer>
          <OnrampHeader disableProgress={disableProgress} inverse={inverse} />
          <TopBanner />
          {promoBannerEnabled && <Banner disabled={isReorder} />}
          {children}
        </MainContainer>
        {/* <Footer /> */}
        {currentUser && <CutoffModal />}
      </Main>
    );
  }, [promoBannerEnabled, inverse, disableProgress, children, currentUser, isReorder]);
}

OnrampLayout.propTypes = {
  promoBannerEnabled: PropTypes.bool,
  inverse: PropTypes.bool,
  disableProgress: PropTypes.bool,
  children: PropTypes.node,
};

const Main = styled.main`
  min-height: 100vh;
  background: #BFD0CA; // FIXME: move to theme
`;

const Banner = styled(PromoBanner)`
  position: relative;
  z-index: ${(p) => p.theme.zIndex.promoBanner};
`;

const MainContainer = styled.section`
  position: relative;
`;

const OnrampHeader = styled(Header)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(p) => p.theme.layout.onramp.headerHeight};
  z-index: ${(p) => p.theme.zIndex.headerFixed};
`;

export default OnrampLayout;
