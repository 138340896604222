function getConstant(name) {
  const constantName = name.toUpperCase();

  if (window && window[constantName]) return window[constantName];

  return process.env[constantName];
}
//FIXME: NOT IN USE FOR NOW
export const eventBannerEnable = getConstant('EVENT_BANNER_ENABLE') === 'true';
export const eventBannerText = getConstant('EVENT_BANNER_TEXT');

export const graphqlHost = getConstant('REACT_APP_GRAPHQL_HOST');
export const stripeAPIKey = getConstant('REACT_APP_STRIPE_API_KEY');
export const facebookId = getConstant('REACT_APP_FACEBOOK_ID');
export const googleAdManagerId = getConstant('REACT_APP_GOOGLE_AD_MANAGER_ID');
export const googleTagManagerEnabled = getConstant('REACT_APP_GOOGLE_TAG_MANAGER_ENABLED') === 'true';
export const nextdoorPixelEnabled = getConstant('REACT_APP_NEXTDOOR_PIXEL_ENABLED') === 'true';
export const tiktokPixelEnabled = getConstant('REACT_APP_TIKTOK_PIXEL_ENABLED') === 'true';
export const shopMyEnabled = getConstant('REACT_APP_SHOPMY_ENABLE') === 'true';
export const shareASaleMerchantId = getConstant('REACT_APP_SHAREASALE_MERCHANT_ID');
export const codeVersion = getConstant('REACT_APP_CODE_VERSION');
export const rollbarAccessToken = getConstant('REACT_APP_ROLLBAR_ACCESS_TOKEN');
export const hostEnv = getConstant('REACT_APP_HOST_ENV') || 'development';
export const clientTimestamp = getConstant('REACT_APP_CLIENT_TIMESTAMP');
export const googleMerchantId = getConstant('REACT_APP_GOOGLE_MERCHANT_ID');

// NOTE: REACT_APP_DEBUG_TRACKING can be overridden in the JS console,
//       so we export a function to get its latest value.
// TODO: replace console overrides with overriding via the query string (parsed in getConstant())
export const debugTrackingEnabled = () => getConstant('REACT_APP_DEBUG_TRACKING') === 'true';
