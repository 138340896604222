import qs from 'qs';

import history from './history';

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const displayNumber = (num) => (num > 0 && num < 10 ? `0${num}` : num.toString());

export const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return null;
  const cleaned = phoneNumberString.replace(/\D/g, '');
  if (cleaned.length !== 10) return phoneNumberString;

  const regex = /^[2-9]\d{2}-?\d{3}-?\d{4}$/;
  if (regex.test(cleaned)) {
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }
  return null;
};


export const normalizeRoute = (path, queryParams = {}) => {
  // Replace multiple slashes with single slash
  let route = path.replace(/\/\/+/g, '/');

  // remove trailing slash, but keep the root slash if it's the only one
  route = route.match(/^\/{1}$/) ? route : route.replace(/\/$/, '');

  // add query params
  const strQueryParams = qs.stringify(queryParams, { skipNulls: true });
  if (strQueryParams) {
    route += `?${strQueryParams}`;
  }

  return route;
};

export const getCurrentPath = () => window.location.pathname;

export const reloadPage = () => window.location.reload();

export const queryStringToObj = (queryString) => qs.parse(queryString, { ignoreQueryPrefix: true });

export const getQueryParams = () => queryStringToObj(window.location.search);

export const setQueryParams = (newQueryParams) => {
  const oldQueryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const queryParams = Object.assign({}, oldQueryParams, newQueryParams);
  const route = normalizeRoute(getCurrentPath(), queryParams);
  history.push(route);
};

const cachedImages = [];
export const cacheImages = (images) => {
  const imagesToCache = Array.isArray(images) ? images : [images];
  imagesToCache.forEach((image) => {
    if (!cachedImages.includes(image)) {
      const cachedImage = new Image();
      cachedImage.src = image;
      cachedImage.className = 'cached-image';
      document.body.appendChild(cachedImage);
      cachedImages.push(image);
    }
  });
};
